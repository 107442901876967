import React, { useMemo, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Preload } from '@react-three/drei'
import { Card } from './Card'
import { generateRandomPosition, getShuffledCards, CardWithPosition } from './utils'
import { BaseCard } from '@/lib/types/index'

interface TarotCardBackgroundProps {
  baseDeck: BaseCard[];
}

export const TarotCardBackground: React.FC<TarotCardBackgroundProps> = ({ baseDeck }) => {
  const [isMobile, setIsMobile] = useState(false)
  const shuffledCards = useMemo(() => getShuffledCards(baseDeck), [baseDeck])

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  const cardsWithPosition = useMemo(() => {
    const cardCount = isMobile ? Math.min(39, shuffledCards.length) : shuffledCards.length
    return shuffledCards.slice(0, cardCount).map(card => ({
      ...card,
      position: generateRandomPosition(isMobile ? 30 : 40),
      speed: Math.random() * 0.02 + 0.05,
    })) as CardWithPosition[]
  }, [shuffledCards, isMobile])

  return (
    <div className="fixed inset-0 -z-10">
      <Canvas 
        camera={{ position: [0, 0, isMobile ? 15 : 10], fov: 75 }}
        dpr={[1, 2]}
      >
        {/* <GradientBackground /> */}
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        {cardsWithPosition.map((card) => (
          <Card key={card.id} card={card} isMobile={isMobile} />
        ))}
        <OrbitControls enableZoom={false} enablePan={false} />
        <Preload all />
      </Canvas>
    </div>
  )
}

