import React from 'react'

interface FeatheredBlurBackdropProps {
  children: React.ReactNode
  blurAmount?: string
  featherSize?: string
}

export function FeatheredBlurBackdrop({
  children,
  blurAmount = '1.5px',
  featherSize = '100px',
}: FeatheredBlurBackdropProps) {
  return (
    <div className="relative w-full h-[100vh] overflow-hidden flex flex-column justify-center items-center">
      <div className="absolute inset-0 backdrop-blur-[1px]" style={{
        backdropFilter: `blur(${blurAmount})`,
        WebkitBackdropFilter: `blur(${blurAmount})`,
        maskImage: `radial-gradient(ellipse 80% 80% at 50% 50%, rgba(0,0,0,0.7) 40%, transparent 80%)`,
        WebkitMaskImage: `radial-gradient(ellipse 80% 80% at 50% 50%, rgba(0,0,0,0.7) 40%, transparent 80%)`,
      }} />
      {children}
    </div>
  )
}

