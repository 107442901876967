import Image from 'next/image'
import Link from 'next/link'

export default function ProductHuntBadge() {
  return (
    <Link
      href="https://www.producthunt.com/posts/arcana-vibes?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-arcana-vibes"
      target="_blank"
      rel="noopener noreferrer"
      className="inline-block"
    >
      <Image
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=693157&theme=neutral"
        alt="Arcana Vibes - On-demand tarot readings based on your astrological chart | Product Hunt"
        width={250}
        height={54}
        priority
        style={{
          maxWidth: '100%',
          height: 'auto'
        }}
        unoptimized
      />
    </Link>
  )
}
