import { Card, BaseCard, SpreadType, TarotSpread } from '@/lib/types/index';
import { v4 as uuidv4 } from 'uuid';

export const shuffleDeck = (deck: BaseCard[]): Card[] => {
    const cardsWithOrientation = deck.map(card => ({
        ...card,
        orientation: Math.random() < 0.5 ? 'reversed' as const : 'upright' as const,
        position: 0
    }));
    
    for (let i = cardsWithOrientation.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [cardsWithOrientation[i], cardsWithOrientation[j]] = 
        [cardsWithOrientation[j], cardsWithOrientation[i]];
    }
    
    return cardsWithOrientation;
};

export const generateSpread = (type: SpreadType, deck: Card[]): Card[] => {
    switch (type) {
        case 'three-card':
            return deck.slice(0, 3); // Past, Present, Future
        case 'celtic-cross':
            return deck.slice(0, 10); // Cross (6) + Staff (4)
        case 'horseshoe':
            return deck.slice(0, 7); // 7 positions
        case 'fan':
            return deck.slice(0, 22); // Significator + 21 aspects
        default:
            throw new Error(`Invalid spread type: ${type}`);
    }
};

export const createSpreadReading = (type: SpreadType, cards: Card[]): TarotSpread => {
    return {
        type,
        cards,
        timestamp: Date.now(),
        id: uuidv4()
    };
}; 