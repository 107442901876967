import React, { useRef, useMemo } from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import * as THREE from 'three'
import { CardWithPosition } from './utils'

interface CardProps {
  card: CardWithPosition;
  isMobile: boolean;
}

export const Card: React.FC<CardProps> = ({ card, isMobile }) => {
  const meshRef = useRef<THREE.Mesh>(null)
  const texture = useLoader(THREE.TextureLoader, card.imagePath)

  const rotationSpeed = useMemo(() => Math.random() * 0.002 + 0.001, [])
  const initialRotation = useMemo(() => Math.random() * Math.PI * 2, [])

  useFrame((state) => {
    if (meshRef.current) {
      // Rotate the card
      meshRef.current.rotation.y = initialRotation + state.clock.getElapsedTime() * rotationSpeed
      meshRef.current.rotation.x = Math.sin(state.clock.getElapsedTime() * 0.5) * 0.1

      // Move the card
      meshRef.current.position.x -= card.speed

      // Reset position when card goes off-screen
      if (meshRef.current.position.x < -50) {
        meshRef.current.position.x = 50
        meshRef.current.position.y = (Math.random() - 0.5) * 20
        meshRef.current.position.z = -Math.random() * 30 - 20
      }
    }
  })

  return (
    <mesh ref={meshRef} position={card.position}>
      <planeGeometry args={isMobile ? [1.5, 2.25] : [2, 3]} />
      <meshBasicMaterial map={texture} side={THREE.DoubleSide} depthWrite={true} transparent opacity={1} />
    </mesh>
  )
}

