import * as THREE from 'three'
import { shuffleDeck } from '@/lib/utils/tarotUtils'
import { BaseCard, Card } from '@/lib/types/index'

export const generateRandomPosition = (radius: number): [number, number, number] => {
  const theta = Math.random() * Math.PI * 2
  const phi = Math.acos(2 * Math.random() - 1)
  const x = radius * Math.sin(phi) * Math.cos(theta)
  const y = radius * Math.sin(phi) * Math.sin(theta)
  const z = -radius * Math.cos(phi) - 30
  return [x, y, z]
}

export const getShuffledCards = (baseDeck: BaseCard[]): Card[] => {
  return shuffleDeck(baseDeck)
}

export interface CardWithPosition extends Omit<Card, 'position'> {
  position: [number, number, number];
  speed: number;
}

