"use client";

import React, { useState, useEffect } from "react";
import Link from "next/link";
import { motion, AnimatePresence } from "framer-motion";
import { Sparkle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { TarotCardBackground } from "./Background/index";
import { useUser } from "@/contexts/UserContext";
import { tarotDeck } from "@/lib/data/tarotDeck";
import { FeatheredBlurBackdrop } from "./FeatheredBlurBackdrop";
import ProductHuntBadge from "./ProductHuntBadge";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

export default function HeroSection() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { userProfile } = useUser();
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const getRedirectUrl = () => {
    if (!userProfile) return "/signup";
    return `/${userProfile.handle}/profile?tab=past-readings`;
  };

  return (
    <>
      <div className="relative min-h-screen">
        <AnimatePresence>
          {isLoaded && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={containerVariants}
              className="relative z-10 min-h-screen flex items-center justify-center px-2"
            >
              <div
                className="absolute inset-0 sm:inset-24 sm:rounded-3xl bg-gradient-to-b from-blue-900 from-0% via-blue-700 via-20% via-sky-500 via-40% via-sky-300 via-60% via-yellow-50 via-[80%] to-red-200 to-100% opacity-60 -z-10"
                style={{
                  filter: "url(#noise)",
                  mixBlendMode: "overlay",
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-b from-blue-900 from-0% via-blue-700 via-20% via-sky-500 via-40% via-sky-300 via-60% via-yellow-50 via-[80%] to-red-200 to-100% opacity-0" />
              <div className="fixed inset-0 w-full h-full">
                <TarotCardBackground baseDeck={tarotDeck} />
              </div>
                <FeatheredBlurBackdrop blurAmount="10px" featherSize="150px">
                  <div className="max-w-4xl mx-auto relative z-10 rounded-3xl p-4 sm:p-6">
                    <div className="flex flex-col items-center pt-8">
                      <motion.h1
                        variants={itemVariants}
                        className="text-5xl drop-shadow-2xl md:text-8xl font-extrabold text-primary mb-1 sm:mb-4 leading-tight font-heading text-center tracking-tighter"
                      >
                        Major Arcana.
                        <br />
                        Major Vibes.
                      </motion.h1>

                      <motion.div
                        variants={itemVariants}
                        className="text-2xl text-primary/80 mb-8 max-w-xl text-center "
                      >
                        <p className="mb-4 sm:mb-6  drop-shadow-2xl text-md sm:text-lg max-w-md mx-auto leading-tight sm:leading-snug">
                          Delve into your past, understand your present, and
                          glimpse your future with personalized tarot readings
                          based on your astrological chart.
                        </p>

                        <Button
                          size="xl"
                          className="bg-white hover:bg-white text-primary hover:text-primary transition-all duration-300 rounded-xl sm:rounded-xl hover:shadow-lg mb-4 sm:mb-6"
                        >
                          <Link href={getRedirectUrl()}>
                            <span className="flex items-center gap-2 text-sm font-subheading">
                              <Sparkle className="h-4 w-4" />
                              {userProfile
                                ? "Go to Your Readings"
                                : "Get a Free Reading!"}
                              <Sparkle className="h-4 w-4" />
                            </span>
                          </Link>
                        </Button>

                        <p className="drop-shadow-2xl text-md sm:text-lg">
                          Receive personalized responses to all of your
                          questions. Readings and conversations are saved so you
                          can pick up where you left off at any time.
                        </p>
                        <div className="block pt-6 sm:hidden">
                          <p className="text-sm text-primary/80">
                            Check out Arcana Vibes on Product Hunt!
                          </p>
                          <div className="pt-2">
                            <ProductHuntBadge />  
                          </div>
                        </div>
                      </motion.div>
                    </div>
                </div>
              </FeatheredBlurBackdrop>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
